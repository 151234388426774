import styles from "./FivaNavbar.module.css"
import Link from "next/link"
import Image from "next/image"
import CompareButton from "../../_SharedComponents/CompareButton/CompareButton"
import { Pages } from "../../../constants/_pages"
import { useRouter } from "next/router"
import { useContext, useEffect, useRef, useState } from "react"
import { CollapseContext } from "../../../context/collapse.context"
import { ChevronDown, ChevronRight } from "lucide-react"

const FivaNavbar = () => {
  const LOGO = "/icons/FivaBig.svg"
  const router = useRouter()
  const { pathname } = router
  const { collapse, setCollapse } = useContext<any>(CollapseContext)
  const contentRef = useRef<any>(null)
  const [height, setHeight] = useState(0)

  let collapseTimeout: NodeJS.Timeout

  useEffect(() => {
    setCollapse(false)
  }, [])

  useEffect(() => {
    if (collapse) {
      setHeight(contentRef.current.scrollHeight)
    } else {
      setHeight(0)
    }
  }, [collapse])

  const handleMouseEnter = () => {
    clearTimeout(collapseTimeout)
    setCollapse(true)
  }

  const handleMouseLeave = () => {
    collapseTimeout = setTimeout(() => {
      setCollapse(false)
    }, 300) // Delay in milliseconds
  }

  const addCollapse = collapse && { minHeight: "56px" }
  const collapseStyle = {
    transition: "height 0.5s ease-out, padding 0.5s ease-out",
    height: `${height}px`,
    overflow: "hidden",
    width: "100%", // Ensuring full width
  }

  return (
    <header className={styles.container} style={{ ...addCollapse }}>
      <div className={styles.flexContainer}>
        <div className={styles.logo}>
          <Link href="/">
            <Image src={LOGO} alt="Fiva Logo" width={130} height={40} />
          </Link>
        </div>
        <div className={styles.links}>
          <Link href="/" passHref>
            <a
              className={
                pathname === "/" ? styles.selectedUnderline : styles.underline
              }
            >
              Ahorra con Fiva
            </a>
          </Link>

          <Link href="/para-empresas" passHref>
            <a
              className={
                pathname === "/para-empresas"
                  ? styles.selectedUnderline
                  : styles.underline
              }
            >
              Para empresas
            </a>
          </Link>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <a
              className={
                pathname === Pages.energy
                  ? styles.selectedUnderline
                  : styles.underline
              }
            >
              Luz
              <ChevronDown strokeWidth="1" size={15} />
            </a>
          </div>
          <Link href="/blog" passHref>
            <a
              className={
                pathname === "/blog"
                  ? styles.selectedUnderline
                  : styles.underline
              }
            >
              Blog
            </a>
          </Link>

          <Link href="/contacto" passHref>
            <a
              className={
                pathname === "/contacto"
                  ? styles.selectedUnderline
                  : styles.underline
              }
            >
              Contacto
            </a>
          </Link>
          <Link href="/colaboradores" passHref>
            <a
              className={
                pathname === "/colaboradores"
                  ? styles.selectedUnderline
                  : styles.underline
              }
            >
              Colaboradores
            </a>
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Link href="/sube-tu-factura" passHref>
            <a style={{ textDecoration: "none" }}>
              <CompareButton />
            </a>
          </Link>
        </div>
      </div>
      {collapse && (
        <div
          className={styles.collapseContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div ref={contentRef} style={collapseStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "var(--fiva-color)",
                padding: "2rem 7%",
                backgroundColor: "var(--fiva-secondary-color, #ddfffd)",
                borderRadius: "40px",
                gap: "16px",
              }}
            >
              <Link href="/precio-luz-hoy" passHref>
                <a className={styles.underlineCollapse}>Precio de la luz</a>
              </Link>
              <Link href="/solar" passHref>
                <a className={styles.underlineCollapse}>Autoconsumo</a>
              </Link>
              <Link href={Pages.energy} passHref>
                <a className={styles.underlineCollapse}>Te asesoramos</a>
              </Link>
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

export default FivaNavbar
